.marguee-image{
    width:60px;
    background-color: none;
    margin-left:10px;
    margin-right:10px;
}

.marquee-wrapper{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    background-color: transparent;
}