.navbar {
    background-color: #1C6758;
    width:100%;
    height:6rem;
    display: flex;
}

.navbar-title{
    display: flex;
    justify-content: space-evenly;
    width: clamp(40%,70%,100%);
    align-items: center;
    margin-left:auto;
    margin-right:auto;
}

.homePhoneScreenMainWrapperSeparator{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    color:white;
    margin-top:-3%;
}

.logoMain{
    display:flex;
    justify-content: space-around;
    width:clamp(45%, 55%, 60%);
    margin-left:clamp(10px,25px,40px);
    margin-right:clamp(10px,25px,40px);
}

.logoMain:hover{
    display:flex;
    justify-content: space-around;
    width:clamp(45%, 55%, 60%);
    margin-left:clamp(10px,25px,40px);
    margin-right:clamp(10px,25px,40px);
    cursor:pointer;
}

.navbar-title>*{
    font-weight:700;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color:#EEF2E6;
}

.navbar-navigation{
    display:flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    height:70%;
}

.navigation-buttons{
    color:#EEF2E6;
    font-weight:600;
    margin-top:auto;
    margin-bottom:auto;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.navigation-buttons:hover{
    cursor: pointer;
    animation-name:hoverButton;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    animation-duration:0.3s;
    animation-fill-mode: forwards;
}

@keyframes hoverButton {
    from {
    } to {
        color:#D6CDA4;
        font-weight:800;
        padding-bottom:5px;
    }
}
