*{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.home{
    background-color:rgb(251,252,249);
}

.home-main-rightButton{
    color:white;
    font-weight:900;
    margin-top:10px;
    width:180px;
    outline:none;
    border:none;
    border-radius: 10rem;
    background-color:rgb(180, 41, 29);
}

.home-main-rightButton:hover{
    margin-top:10px;
    background-color:#1C6758;
    color:white;
    border:1px black solid;
}

.homePhoneScreenMainWrapper{
    color:white;
    margin-left:auto;
    margin-right:auto;
    padding-top:30px;
    height:28vh;
    background-color: #1C6758;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
}



