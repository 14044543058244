.machine-pics{
    height:500px;
    width:290px;
}

#our-machines-main-text{
    margin-left:auto;
    margin-right:auto;
    text-align: start;
    margin-bottom:40px;
    margin-top:30px;
}

.our-machines-wrapper{
    margin-right:auto !important;
    margin-left:auto !important;
    width:clamp(90%, calc(40vw + 12rem), 95vw);
}

.our-machines-extra{
    text-align: start;
    width:100%;
    margin-left:auto;
    margin-right:auto;
}   