.footerWrapper{
    width:100%;
    height:20vh;
    background-color: #1C6758;
    padding-top:2.5%;
}

.footerWrapper>p{
    line-height: 7px;
    font-weight:900;
    font-size:smaller;
}